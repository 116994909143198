
import { useForm, Controller } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import {useSearchParams} from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import * as Realm from "realm-web";
import { useState } from "react";
import Alert from "./alert";

const app = new Realm.App({ id: process.env.REACT_APP_REALM_APP});

const schema = yup.object().shape({
    password: yup.string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters"),
    cpassword: yup.string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(12, "Password cannot exceed more than 12 characters")
      .oneOf([yup.ref("password")], "Passwords do not match")
  });
  
const App = () => {
 const [loading,setLoading] = useState(false)
 const [toggleScreen, setToggleScreen] = useState(false);
 const [openSnackBar, setOpenSnackBar] = useState(false);
  const { control, handleSubmit, formState:{ errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        password: '',
        cpassword: ''
    }
  });
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')
  const tokenId = searchParams.get('tokenId')

  const onSubmit = async(data) => {
    setLoading(true)
    try{
        await app.emailPasswordAuth.resetPassword({
            password: data.password,
            token,
            tokenId,
        });
        setToggleScreen(true)
        setLoading(false)
    }catch(err){
        console.log(err.message)
        setOpenSnackBar(true)
        setLoading(false)
    }
    console.log(data)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <section className="banner">
        <div className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ maxWidth: '100%', padding:'20px'}}>
            {!toggleScreen ? (
              <Stack spacing={2}>
              <h2 style={{textAlign:'center'}}>Reset Password</h2>
              <Controller
                  name="password"
                  control={control}
                  render={({ field }) => {
                  return (<>
                          <TextField type='password' fullWidth id="new-pass-input" label="New Password" variant="outlined" {...field} />
                          <p style={{margin:0, color:'red', fontSize:'12px', paddingTop:'2px'}}>{errors.password?.message}</p>
                      </>)}
                  }
              />
              <Controller
                      name="cpassword"
                      control={control}
                      render={({ field }) => {
                          return (<>
                                  <TextField type='password' fullWidth id="conf-pass-input" label="Confirm Password" variant="outlined" {...field} />
                                  <p style={{margin:0, color:'red', fontSize:'12px', paddingTop:'2px'}}>{errors.cpassword?.message}</p>
                              </>)}
                          }
              />
              <LoadingButton loading={loading} variant="contained" type="submit">Submit</LoadingButton>
              <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleClose} >
                  <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Reset password request has been expired!
                  </Alert>
              </Snackbar>
          </Stack>
            ):(
              <div style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                <h2 style={{textAlign:'center'}}>You have successfully reset your password!</h2>
                <h4 style={{textAlign:'center'}}>Go back to the app and use your new password to login</h4>
              </div>
            )}
          </Box>
      </form>
    </div>
  </section>
    
  );
};
export default App;