import React from 'react';

const Terms = () =>{
    return(
        <section className="privacy common-content">
            <div className="container">
                <div>
                    <h2>Terms</h2>
                </div>
            </div>
        </section>
        
    )
}
export default Terms