import React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import Landing from '../Screens/Landing';
import ResetPassword from '../Screens/ResetPassword';
import Terms from '../Screens/Terms';
import Privacy from '../Screens/Privacy'


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  }
]);
