import React from "react";
import Logo from "../Assets/Images/AazthyLogo-updated.svg";
const Header = () => {
  return (
    <header>
      <div className="container header-inner">
        <a href="/" className="logo">
          <img
            src={Logo}
            className="img-fluid"
            alt="website_logo"
            style={{ width: "240px" }}
          />
        </a>
      </div>
    </header>
  );
};
export default Header;
