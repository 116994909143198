import React from 'react';
import Logo from '../Assets/Images/© 2023 Aazthy.png'
const Footer = () =>{
    return(
        <footer>
            <div class="container footer-inner">
                <div class="footer-left">
                    <a href="/" class="logo">
                        <img src={Logo} width="50" class="img-fluid" alt="PTLS_Logo" />
                    </a>
                </div>
                <ul>
                    <li><a href="/privacy">Privacy Policy</a> </li> 
                    <li ><a href="mailto:info@aazthy.com" target="_blank" rel="noreferrer">Contact Us</a> </li>
                </ul>
            </div>
        </footer>
    )
}
export default Footer;